import { Link } from 'gatsby';
import React from 'react';

const NotFound = () => {
  return (
    <section className="main-lost" aria-label="404 Not Found">
      <div className="main-lost__container">
        <h1 className="main-lost__title">404 Error</h1>
        <p className="main-lost__paragraph">
          Oops! The resource you were trying to access may have been moved or deleted. Please make sure
          that the URL is correct and try again!
        </p>
        <p className="main-lost__paragraph">
          Click the link below to return to the Glendale Builders home page.
        </p>
        <Link to="/" className="main-lost__link">
          Return to Glendale Builders Home Page
        </Link>
      </div>
    </section>
  );
};

export default NotFound;
